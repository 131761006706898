import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { async_removeuser } from "../../store/Actions/Authactions";

const TutorPannel = () => {
  const dispatch = useDispatch();
  const LogoutHandler = () => {
    dispatch(async_removeuser());
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/Tutor") {
      navigate("/Tutor/Tutor-StudentDashboard"); 
    }
  }, []);


  const user = useSelector((state) => state.auth.user);

  return (
    <div className="col-md-12 bg-gradient-to-b from-blue-900 to-white h-screen flex">
      <div className="w-[15%]  p-2">
        <div className="w-100 p-2">
          <h6 className="text-white">Welcome to XL Education</h6>
          {/* <h4 className="text-white">{user.Username}</h4> */}
        </div>
        <div className="w-100  mt-2 items-center admin_links_box_padding">
          <Link
            to="Tutor-StudentAccount_Form"
            className="text-lg mx-3 text-white"
          >
            Onboard Student
          </Link>
        </div>
        <div className="w-100  mt-2 items-center admin_links_box_padding">
          <Link
            to="Tutor-RegisteredStudent"
            className="text-lg mx-3 text-white"
          >
            Manage Students
          </Link>
        </div>
        <div className="w-100  mt-2 items-center admin_links_box_padding">
          <Link
            to="Tutor-StudentLeaderboard"
            className="text-lg mx-3 text-white"
          >
            LeaderBoard
          </Link>
        </div>
        <div className="w-100  mt-2 items-center admin_links_box_padding">
          <Link to="Tutor-StudentDashboard" className="text-lg mx-3 text-white">
            Dashboard
          </Link>
        </div>
        <div className="w-100 mt-2 items-center admin_links_box_padding">
          <Link to="Tutor-Home" className="text-lg mx-3 text-white">
            Edit Profile
          </Link>
        </div>
        <div className="w-100  mt-2 items-center admin_links_box_padding">
          <button
            // to="Tutor-RegisteredStudent"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={LogoutHandler}
          >
            Logout
          </button>
        </div>
      </div>
      <div style={{ backgroundColor: "#03051B" }} className="w-[85%]  p-2 flex">
        <Outlet /> {/* This renders the child routes */}
      </div>
    </div>
  );
};

export default TutorPannel;
