import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Change_Tutor_Password,
  Edit_Tutor_Profile,
} from "../../store/Actions/Authactions";
import { useNavigate } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const TutorHome = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0); // State to manage active tab

  const [formData, setFormData] = useState({
    TutorID: "",
    Username: "",
    Email: "",
    CurrentPassword: "",
    NewPassword: "",
    ConfirmNewPassword: "",
  });

  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  // State for password visibility toggling
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmNewPasswordVisible, setConfirmNewPasswordVisible] =
    useState(false);

  useEffect(() => {
    if (user) {
      setFormData({
        TutorID: user._id,
        Username: user.Username,
        Email: user.Email,
        CurrentPassword: "",
        NewPassword: "",
        ConfirmNewPassword: "",
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const navigate = useNavigate();

  const validatePasswordInputs = () => {
    let isValid = true;

    if (formData.CurrentPassword.trim() === "") {
      setCurrentPasswordError("Current password is required");
      isValid = false;
    }

    if (
      !/(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}/.test(
        formData.NewPassword
      )
    ) {
      setNewPasswordError(
        "Password must be 8 or more characters long and contain at least one letter, one number, and one special character"
      );
      isValid = false;
    } else {
      setNewPasswordError("");
    }

    if (formData.NewPassword !== formData.ConfirmNewPassword) {
      setConfirmPasswordError("Passwords do not match");
      isValid = false;
    } else {
      setConfirmPasswordError("");
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (activeTab === 0) {
      dispatch(
        Edit_Tutor_Profile({
          TutorID: formData.TutorID,
          Username: formData.Username,
          Email: formData.Email,
        })
      );
    } else {
      if (validatePasswordInputs()) {
        dispatch(
          Change_Tutor_Password({
            TutorID: formData.TutorID,
            CurrentPassword: formData.CurrentPassword,
            NewPassword: formData.NewPassword,
            ConfirmNewPassword: formData.ConfirmNewPassword,
          })
        );
        navigate("/Tutor/Tutor-StudentAccount_Form");
      }
    }
  };

  return (
    <div className="w-full">
      <div className="text-white text-center mt-5 text-xl">
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px">
            <li className="me-2">
              <div
                className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg cursor-pointer ${
                  activeTab === 0
                    ? "text-blue-600 border-blue-600"
                    : "hover:text-gray-600 hover:border-gray-300"
                }`}
                onClick={() => handleTabClick(0)}
              >
                Edit Profile
              </div>
            </li>
            <li className="me-2">
              <div
                className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg cursor-pointer ${
                  activeTab === 1
                    ? "text-blue-600 border-blue-600"
                    : "hover:text-gray-600 hover:border-gray-300"
                }`}
                onClick={() => handleTabClick(1)}
              >
                Change Password
              </div>
            </li>
          </ul>
        </div>

        <form className="mt-5" onSubmit={handleSubmit}>
          {activeTab === 0 ? (
            <>
              {/* Profile Edit Form */}
              <label className="block mb-2 text-xl font-medium text-white text-start mx-1 mt-5">
                Name
              </label>
              <input
                name="Username"
                placeholder="Name"
                className="mt-5 p-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                value={formData.Username}
                onChange={handleChange}
              />
              <label className="block mb-2 text-xl font-medium text-white text-start mx-1 mt-5">
                Email
              </label>
              <input
                name="Email"
                placeholder="Email"
                className="mt-5 p-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                value={formData.Email}
                onChange={handleChange}
              />
            </>
          ) : (
            <>
              {/* Password Change Form */}
              <label className="block mb-2 text-xl font-medium text-white text-start mx-1 mt-5">
                Current Password
              </label>
              <div className="relative">
                <input
                  type={currentPasswordVisible ? "text" : "password"}
                  name="CurrentPassword"
                  placeholder="Current Password"
                  className="mt-5 p-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                  value={formData.CurrentPassword}
                  onChange={handleChange}
                />
                <span
                  onClick={() =>
                    setCurrentPasswordVisible(!currentPasswordVisible)
                  }
                  className="absolute right-3 top-2.5 cursor-pointer text-black"
                >
                  {currentPasswordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                </span>
              </div>
              {currentPasswordError && (
                <span className="text-red-500">{currentPasswordError}</span>
              )}

              <label className="block mb-2 text-xl font-medium text-white text-start mx-1 mt-5">
                New Password
              </label>
              <div className="relative">
                <input
                  type={newPasswordVisible ? "text" : "password"}
                  name="NewPassword"
                  placeholder="New Password"
                  className="mt-5 p-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                  value={formData.NewPassword}
                  onChange={handleChange}
                />
                <span
                  onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                  className="absolute right-3 top-2.5 cursor-pointer text-black"
                >
                  {newPasswordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                </span>
              </div>
              {newPasswordError && (
                <span className="text-red-500">{newPasswordError}</span>
              )}

              <label className="block mb-2 text-xl font-medium text-white text-start mx-1 mt-5">
                Confirm New Password
              </label>
              <div className="relative">
                <input
                  type={confirmNewPasswordVisible ? "text" : "password"}
                  name="ConfirmNewPassword"
                  placeholder="Confirm New Password"
                  className="mt-5 p-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                  value={formData.ConfirmNewPassword}
                  onChange={handleChange}
                />
                <span
                  onClick={() =>
                    setConfirmNewPasswordVisible(!confirmNewPasswordVisible)
                  }
                  className="absolute right-3 top-2.5 cursor-pointer text-black"
                >
                  {confirmNewPasswordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                </span>
              </div>
              {confirmPasswordError && (
                <span className="text-red-500">{confirmPasswordError}</span>
              )}
            </>
          )}

          <button
            type="submit"
            className="mt-5 py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
          >
            {activeTab === 0 ? "Update Profile" : "Change Password"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default TutorHome;
