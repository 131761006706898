import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Get_Count_Student,
  GetAllStudent_Tutor_Data,
} from "../../store/Actions/Authactions";
import { Get_Weekly_Performance_of_Student_All } from "../../store/Actions/LeaderBoardActions";
import "../../Styles/Dashboard.css";
import ChartComponent from "../../Pages/ChartComponent";

const TutorStudentDashboard = () => {
  const [totalQuestion, setTotalQuestions] = useState(0);
  const [correctQuestions, setCorrectQuestions] = useState(0);
  const [wrongQuestions, setWrongQuestions] = useState(0);
  const [totalWordPractice, setTotalWordPracticed] = useState(0);
  const [Selected_Student, setSelected_Student] = useState("");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const AllStudent_Tutor_Data = useSelector(
    (state) => state.auth.AllStudent_Tutor_Data
  );

  const AllDashboardData = useSelector(
    (state) => state.LeaderBoard.DashboardData
  );

  const WordCount = useSelector((state) => state.auth?.WordCount);

  useEffect(() => {
    if (user && user._id) {
      dispatch(GetAllStudent_Tutor_Data(user._id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (Selected_Student) {
      dispatch(Get_Weekly_Performance_of_Student_All(Selected_Student));
      dispatch(Get_Count_Student(Selected_Student));
    }
  }, [dispatch, Selected_Student]);

  console.log(AllDashboardData, "--------------AllDashboardData");

  useEffect(() => {
    if (AllDashboardData.length > 0) {
      const totalqueValue = AllDashboardData.map(
        (ele) => ele.TotalquestionsattemptedCount
      ).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      const totalCorrectValue = AllDashboardData.map(
        (ele) => ele.QuestionsCorrectCount
      ).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      const totalWrongValue = AllDashboardData.map(
        (ele) => ele.QuestionsWrongCount
      ).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      setTotalQuestions(totalqueValue);
      setCorrectQuestions(totalCorrectValue);
      setWrongQuestions(totalWrongValue);
      setTotalWordPracticed(WordCount[0]?.Count || 0);
    } else {
      // Reset to initial state if no data is available
      setTotalQuestions(0);
      setCorrectQuestions(0);
      setWrongQuestions(0);
      setTotalWordPracticed(0);
    }
  }, [AllDashboardData, WordCount]);

  return (
    <>
      <div className="text-white dashboard-bg2">
        <div>
          <div className="flex flex-col py-2">
            <label className="font-poppins text-white userInfoText">
              I am a
            </label>
            <div className="relative">
              <select
                value={Selected_Student}
                onChange={(e) => setSelected_Student(e.target.value)}
                required
                className="userInfoBox bg-blue-500 appearance-none"
                style={{ backgroundColor: "#1D1E32", width: "100%" }}
              >
                <option value="" disabled>
                  Please Select the Student whose data you want to fetch in the
                  Dashboard
                </option>
                {AllStudent_Tutor_Data?.map((val, index) => (
                  <option key={index} value={val._id}>
                    {val.Username}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                ></svg>
              </div>
            </div>
          </div>
          <div>
            <h4 className="chart-h">My Tutor Student Activity</h4>
            <div className="activity-container">
              <div className="ab-1">
                <div className="activity-box">
                  <img src={require("../../Assets/Images/d-4.png")} alt="" />
                  <div>
                    <p>Total Questions</p>
                    <h5>{totalQuestion}</h5>
                  </div>
                </div>
                <div className="activity-box">
                  <img src={require("../../Assets/Images/d-3.png")} alt="" />
                  <div>
                    <p>Correct Answers</p>
                    <h5>{correctQuestions}</h5>
                  </div>
                </div>
              </div>
              <div className="ab-1">
                <div className="activity-box">
                  <img src={require("../../Assets/Images/d-2.png")} alt="" />
                  <div>
                    <p>Wrong Answered</p>
                    <h5>{wrongQuestions}</h5>
                  </div>
                </div>
                <div className="activity-box">
                  <img src={require("../../Assets/Images/d-1.png")} alt="" />
                  <div>
                    <p>Total Words Practised</p>
                    <h5>{totalWordPractice}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="activity-container">
              <div style={{ color: "white" }}>
                <ChartComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TutorStudentDashboard;

