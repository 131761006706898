import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Update_Student_Tutor } from "../../store/Actions/Authactions";

const TutorManageStudent = () => {
  const { StudentID } = useParams();
  const AllStudent_Tutor_Data = useSelector(
    (state) => state.auth.AllStudent_Tutor_Data
  );

  const FilteredData = AllStudent_Tutor_Data
    ? AllStudent_Tutor_Data.filter((val) => val._id === StudentID)
    : [];

  const [FormData, setFormData] = useState({
    StudentID:"",
    Username: "",
    Children_Name: "",
    Email: "",
    UserType: "",
    buyPlan: true,
    isFreePlan: true,
    Privacy_Terms_Checked_At: "",
    isCancelled: true,
    FromGoogle: true,
    sessionID: "",
    createdDate: "",
  });

  useEffect(() => {
    if (FilteredData.length > 0) {
      setFormData({
        StudentID:FilteredData[0]?._id,
        Username: FilteredData[0]?.Username,
        Children_Name: FilteredData[0]?.Children_Name,
        Email: FilteredData[0]?.Email,
        UserType: FilteredData[0]?.UserType,
        buyPlan: FilteredData[0]?.buyPlan,
        isFreePlan: FilteredData[0]?.isFreePlan,
        Privacy_Terms_Checked_At: FilteredData[0]?.Privacy_Terms_Checked_At,
        isCancelled: FilteredData[0]?.isCancelled,
        FromGoogle: FilteredData[0]?.FromGoogle,
        Payment_sessionID: FilteredData[0]?.PaymentsID[0]?.sessionID,
        Payment_createdDate: FilteredData[0]?.PaymentsID[0]?.createdDate,
      });
    }
  }, []);

  const HandleChange = (e) => {
    const { name, value } = e.target;

    // Check if the field is supposed to be a boolean value and convert "true"/"false" strings to booleans
    const booleanFields = [
      "buyPlan",
      "isFreePlan",
      "isCancelled",
      "FromGoogle",
    ];
    const newValue = booleanFields.includes(name) ? value === "true" : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };


const dispatch  = useDispatch()
const navigate   =  useNavigate()
  const HandleSubmit = (e) => {
    e.preventDefault();
    dispatch(Update_Student_Tutor(FormData))
    navigate("/Tutor/Tutor-RegisteredStudent");

  };

  return (
    <div className="w-full mt-5">
      <h1>TutorManageStudent</h1>
      <p>
        Student ID: {StudentID} <br />
        These are the details of this particular student:
      </p>

      {FilteredData.length > 0 ? (
        FilteredData.map((val, index) => (
          <div key={index}>
            <h2>StudentID: {val?._id}</h2>
            <h2>Username: {val?.Username}</h2>
            <h2>Children_Name: {val?.Children_Name}</h2>
            <h2>Email: {val?.Email}</h2>
            <h2>UserType: {val?.UserType}</h2>
            <h2>buyPlan: {val?.buyPlan ? "YES" : "NO"}</h2>
            <h2>createdAt: {val?.createdAt}</h2>
            <h2>isFreePlan: {val?.isFreePlan ? "YES" : "NO"}</h2>
            <h2>isCancelled: {val?.isCancelled ? "YES" : "NO"}</h2>
            <h2>Privacy_Terms_Checked_At: {val?.Privacy_Terms_Checked_At}</h2>
            <h2>FromGoogle: {val?.FromGoogle ? "YES" : "NO"}</h2>
            <h2>PaymentsID - sessionID: {val?.PaymentsID[0]?.sessionID}</h2>
            <h2>PaymentsID - createdDate: {val?.PaymentsID[0]?.createdDate}</h2>
          </div>
        ))
      ) : (
        <p>No student found with this ID.</p>
      )}

      {/* Form inputs */}
      <form onSubmit={HandleSubmit}>
        <div className="flex w-full justify-evenly mt-5">
          <input
            name="Username"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-green-500"
            placeholder="Username"
            value={FormData.Username}
            onChange={HandleChange}
          />
          <input
            name="Children_Name"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-green-500"
            placeholder="Children_Name"
            value={FormData.Children_Name}
            onChange={HandleChange}
          />
        </div>

        <div className="flex w-full justify-evenly mt-5">
          <input
            name="Email"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-green-500"
            placeholder="Email"
            value={FormData.Email}
            onChange={HandleChange}
          />
          <input
            name="UserType"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-green-500"
            placeholder="UserType"
            value={FormData.UserType}
            // onChange={HandleChange}
          />
        </div>

        {/* Replaced boolean fields with select inputs */}
        <div className="flex w-full justify-evenly mt-5">
          <select
            name="buyPlan"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-green-500"
            value={FormData.buyPlan ? "true" : "false"}
            onChange={HandleChange}
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>

          <select
            name="isFreePlan"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-green-500"
            value={FormData.isFreePlan ? "true" : "false"}
            onChange={HandleChange}
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>

        <div className="flex w-full justify-evenly mt-5">
          <input
            name="Privacy_Terms_Checked_At"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-green-500"
            placeholder="Privacy_Terms_Checked_At"
            value={FormData.Privacy_Terms_Checked_At}
            // onChange={HandleChange}
          />
          <select
            name="isCancelled"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-green-500"
            value={FormData.isCancelled ? "true" : "false"}
            onChange={HandleChange}
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>

        <div className="flex w-full justify-evenly mt-5">
          <select
            name="FromGoogle"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-green-500"
            value={FormData.FromGoogle ? "true" : "false"}
            onChange={HandleChange}
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>

          <input
            name="Payment_sessionID"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-green-500"
            placeholder="Payment_sessionID"
            value={FormData.Payment_sessionID}
          />
        </div>

        <div className="flex w-full justify-evenly mt-5">
          <input
            name="Payment_createdDate"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-green-500"
            placeholder="Payment_createdDate"
            value={FormData.Payment_createdDate}
          />
        </div>
        <button
          type="submit"
          className="mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          Update the Student
        </button>
      </form>
    </div>
  );
};

export default TutorManageStudent;
