// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Delete_Student,
//   GetAllStudent_Tutor_Data,
// } from "../../store/Actions/Authactions";
// import { useNavigate } from "react-router-dom";

// const TutorRegisteredStudent = () => {
//   const user = useSelector((state) => state.auth.user);
//   const AllStudent_Tutor_Data = useSelector(
//     (state) => state.auth.AllStudent_Tutor_Data
//   );

//   const navigate = useNavigate();
//   // console.log(user, "-*-----------user");
//   // console.log(AllStudent_Tutor_Data, "------------AllStudent_Tutor_Data");
//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (user) {
//       const TutorID = user._id;
//       dispatch(GetAllStudent_Tutor_Data(TutorID));
//     }
//   }, [user]);

//   const DeleteHandler = (StudentID) => {
//     dispatch(Delete_Student(StudentID));
//     navigate("/Tutor/Tutor-StudentAccount_Form");
//   };

//   return (
//     <div>
//       <div className="relative overflow-x-auto">
//         {AllStudent_Tutor_Data.length > 0 ? (
//           <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
//             <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
//               <tr>
//                 <th scope="col" className="px-6 py-3">
//                   Username
//                 </th>
//                 <th scope="col" className="px-6 py-3">
//                   Email
//                 </th>
//                 <th scope="col" className="px-6 py-3">
//                   UserType
//                 </th>
//                 <th scope="col" className="px-6 py-3">
//                   Children_Name
//                 </th>
//                 <th scope="col" className="px-6 py-3">
//                   buyPlan
//                 </th>
//                 <th scope="col" className="px-6 py-3">
//                   Privacy_Terms_Checked_At
//                 </th>
//                 <th scope="col" className="px-6 py-3">
//                   Active_Plan
//                 </th>
//                 <th scope="col" className="px-6 py-3">
//                   Active_Plan_Amount
//                 </th>
//                 {/* <th scope="col" className="px-6 py-3">
//                   Stripe_customer_ID
//                 </th> */}
//                 <th scope="col" className="px-6 py-3">
//                   MANAGE
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {AllStudent_Tutor_Data.map((Student, index) => (
//                 <tr
//                   key={index}
//                   className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
//                 >
//                   <th
//                     scope="row"
//                     className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
//                   >
//                     {Student?.Username}
//                   </th>
//                   <td className="px-6 py-4">{Student?.Email}</td>
//                   <td className="px-6 py-4">{Student?.UserType}</td>
//                   <td className="px-6 py-4">{Student?.Children_Name}</td>
//                   <td className="px-6 py-4">
//                     {Student.buyPlan ? "Yes" : "No"}
//                   </td>
//                   <td className="px-6 py-4">
//                     {Student?.Privacy_Terms_Checked_At}
//                   </td>
//                   <td className="px-6 py-4">
//                     {Student?.Active_Plan || "NO PLAN ACTIVE"}
//                   </td>
//                   <td className="px-6 py-4">
//                     {Student?.Active_Plan_Amount || "NO PLAN ACTIVE"}
//                   </td>
//                   {/* <td className="px-6 py-4">
//                     {Student?.Stripe_customer_ID || "NO PLAN ACTIVE"}
//                   </td> */}
//                   <td className="px-6 py-4">
//                     <button
//                       className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
//                       //  onClick={() => navigate(`/Tutor/Tutor-RegisteredStudent/${Student._id}`)}
//                       onClick={() => DeleteHandler(Student._id)}
//                     >
//                       Delete this Student Account
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         ) : null}
//       </div>
//     </div>
//   );
// };

// export default TutorRegisteredStudent;


import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Delete_Student,
  GetAllStudent_Tutor_Data,
} from "../../store/Actions/Authactions";
import { useNavigate } from "react-router-dom";

const TutorRegisteredStudent = () => {
  const user = useSelector((state) => state.auth.user);
  const AllStudent_Tutor_Data = useSelector(
    (state) => state.auth.AllStudent_Tutor_Data
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const TutorID = user._id;
      dispatch(GetAllStudent_Tutor_Data(TutorID));
    }
  }, [user]);

  const DeleteHandler = (StudentID) => {
    const FormData = {
      StudentID,
      TutorID: user?._id
    };
    dispatch(Delete_Student(FormData));
    navigate("/Tutor/Tutor-StudentAccount_Form");
  };

  return (
    <div>
      <div className="relative overflow-x-auto">
        {AllStudent_Tutor_Data.length > 0 ? (
          <table className="w-full text-sm text-left rtl:text-right text-white">
            <thead className="text-xs text-white uppercase bg-[#03051B]">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Username
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  UserType
                </th>
                <th scope="col" className="px-6 py-3">
                  Children_Name
                </th>
                <th scope="col" className="px-6 py-3">
                  buyPlan
                </th>
                <th scope="col" className="px-6 py-3">
                  Privacy_Terms_Checked_At
                </th>
                <th scope="col" className="px-6 py-3">
                  Active_Plan
                </th>
                <th scope="col" className="px-6 py-3">
                  Active_Plan_Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  MANAGE
                </th>
              </tr>
            </thead>
            <tbody>
              {AllStudent_Tutor_Data.map((Student, index) => (
                <tr
                  key={index}
                  className="bg-[#03051B] border-b border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-white whitespace-nowrap"
                  >
                    {Student?.Username}
                  </th>
                  <td className="px-6 py-4">{Student?.Email}</td>
                  <td className="px-6 py-4">{Student?.UserType}</td>
                  <td className="px-6 py-4">{Student?.Children_Name}</td>
                  <td className="px-6 py-4">
                    {Student.buyPlan ? "Yes" : "No"}
                  </td>
                  <td className="px-6 py-4">
                    {Student?.Privacy_Terms_Checked_At}
                  </td>
                  <td className="px-6 py-4">
                    {Student?.Active_Plan || "NO PLAN ACTIVE"}
                  </td>
                  <td className="px-6 py-4">
                    {Student?.Active_Plan_Amount || "NO PLAN ACTIVE"}
                  </td>
                  <td className="px-6 py-4">
                    <button
                      className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                      onClick={() => DeleteHandler(Student._id)}
                    >
                      Delete this Student Account
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
};

export default TutorRegisteredStudent;
