import React, { useEffect, useRef, useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { Signup_Tutor_Student } from "../../store/Actions/Authactions";

const TutorStudent_Form = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [UserName, setUserName] = useState("");
  const [childrenName, setChildrenName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [UserNameError, setUserNameError] = useState("");
  const [childrenError, SetChildrenError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [childNameWithGoogle, setChildNameWithGoogle] = useState(false);
  const [childNameFromGoogle, setChildNameFromGoogle] = useState("");
  const [submitChildNamePopup, setSubmitChildNamePopUp] = useState(false);
  const [decodedToken, setDecodedToken] = useState(null);
  const [ShowTermsViaGoogle, setShowTermsViaGoogle] = useState(false);
  const [isTermsViaGoogleChecked, setisTermsViaGoogleChecked] = useState(false);
  const [TermsError, setTermsError] = useState("");

  const googleLoginButtonRef = useRef(null);

  const handleTermsChange = () => {
    setTermsChecked(!termsChecked);
  };

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userType = useSelector((state) => state.auth.userType);
  const user = useSelector((state) => state.auth.user);

  console.log(isAuthenticated, "------------is Authenticate--------------");

  console.log(userType, "------------usertype-");

  const validateInputs = () => {
    let isValid = true;

    const normalizedUserName = UserName.trim().toLowerCase();
    const normalizedEmail = email.trim().toLowerCase();

    if (UserName.trim() === "") {
      setUserNameError("Full name is required");
      isValid = false;
    } else if (normalizedUserName.includes("admin")) {
      setUserNameError(
        "You cannot sign up with this name. Please use a different name."
      );
      isValid = false;
    }

    if (childrenName.trim() === "") {
      SetChildrenError("Children name is required");
      isValid = false;
    }

    if (email.trim() === "") {
      setEmailError("Email address is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email address is invalid");
      isValid = false;
    } else if (normalizedEmail.includes("admin")) {
      setEmailError(
        "You cannot sign up with this email. Please use a different email."
      );
      isValid = false;
    }

    if (password.trim() === "") {
      setPasswordError("Password is required");
      isValid = false;
    } else if (
      !/(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}/.test(
        password
      )
    ) {
      setPasswordError(
        "Password must be 8 or more characters long and contain at least one letter, one number, and one special character"
      );
      isValid = false;
    }

    if (!termsChecked) {
      setTermsError("Please agree to the terms");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInputs()) {
      dispatch(
        Signup_Tutor_Student({
          Username: UserName,
          Email: email,
          Password: password,
          Children_Name: childrenName,
          TutorID: user?._id,
        })
      ).then(() => {
        setUserName("");
        setChildrenName("");
        setEmail("");
        setPassword("");
        // navigate("/loggedInHome");
      });
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div
      className="min-h-screen flex w-full items-center justify-center"
      style={{ backgroundColor: "#03051B" }}
    >
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h1 className="text-2xl font-semibold mb-4 text-center">
          OnBoard Student Form
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2 text-gray-700">Parent Name</label>
            <input
              type="text"
              placeholder="Parent Name"
              value={UserName}
              onChange={(e) => {
                setUserName(e.target.value);
                setUserNameError("");
              }}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
            />
            {UserNameError && (
              <span className="text-red-500">{UserNameError}</span>
            )}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-gray-700">Children Name</label>
            <input
              type="text"
              value={childrenName}
              placeholder="Children Name"
              onChange={(e) => {
                setChildrenName(e.target.value);
                SetChildrenError("");
              }}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
            />
            {childrenError && (
              <span className="text-red-500">{childrenError}</span>
            )}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-gray-700">Email Address</label>
            <input
              type="email"
              value={email}
              placeholder="Email Address"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");
              }}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
            />
            {emailError && <span className="text-red-500">{emailError}</span>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-gray-700">Password</label>
            <div className="relative">
              <input
                type={passwordVisible ? "text" : "password"}
                value={password}
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError("");
                }}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
              />
              <span
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-2.5 cursor-pointer"
              >
                {passwordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
              </span>
            </div>
            <p className="text-sm text-gray-600 mt-1">
              Use 8 or more characters with a mix of letters, numbers & symbols.
            </p>
            {passwordError && (
              <span className="text-red-500">{passwordError}</span>
            )}
          </div>

          <div className="mb-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={termsChecked}
                onChange={handleTermsChange}
                className="form-checkbox"
              />
              <span className="ml-2 text-gray-700">
                By creating an account, I agree to the{" "}
                <Link to="/TermsOfUse" className="text-blue-500 underline">
                  Terms of Use
                </Link>{" "}
                and{" "}
                <Link to="/PolicyPrivacy" className="text-blue-500 underline">
                  Privacy Policy
                </Link>
              </span>
            </label>
            {TermsError && <div className="text-red-500">{TermsError}</div>}
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Sign Up
          </button>

          <div className="mt-4 text-center">
            <p>
              Already have an account?{" "}
              <Link to="/SignInPage" className="text-blue-500 underline">
                Log in
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TutorStudent_Form;
