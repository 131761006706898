import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helper/axiosconfig";

// ACTIONS : api calls

// --------------------------------------------------------------------------------- auth  --------

// ;

export const Signup_Tutor_Student = createAsyncThunk(
  "auth/Signup_Tutor_Student",
  async (formData) => {
    try {
      const response = await axios.post(`Signup_Tutor_Student`, formData);
      console.log(
        response,
        "----------------message response of Signup_Tutor_Student"
      );
      return response;
    } catch (error) {
      // console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const SignUp_user = createAsyncThunk("auth/Signup", async (formData) => {
  try {
    const response = await axios.post(`Signup`, formData);
    // console.log(response);
    return response.data;
  } catch (error) {
    // console.log(error.response.data.message);
    return error.response.data.message;
  }
});

export const async_loaduser = createAsyncThunk(
  "auth/async_loaduser",
  async () => {
    const response = await axios.post(`me`);
    console.log(response, "respons eof the me");
    return response.data.user;
  }
);

export const async_removeuser = createAsyncThunk(
  "auth/async_removeuser",
  async () => {
    const response = await axios.get(`signout`);
    // console.log(response)
    return response;
  }
);

export const Signin_user = createAsyncThunk(
  "auth/Signin_user",
  async (formData) => {
    try {
      const response = await axios.post(`Signin_user`, formData);
      // console.log(response);
      return response.data;
    } catch (error) {
      // console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const Find_Email = createAsyncThunk("auth/Find_Email", async (Email) => {
  try {
    // console.log(Email)
    const response = await axios.post(`FindUsername`, Email);
    // console.log(response);
    return response.data;
  } catch (error) {
    // console.log(error.response.data.message);
    return error.response.data.message;
  }
});

export const MatchOTP = createAsyncThunk("auth/MatchOTP", async (data) => {
  try {
    // console.log(data)
    const response = await axios.post(`MatchOTP`, data);
    // console.log(response);
    return response.data;
  } catch (error) {
    // console.log(error.response.data.message);
    return error.response.data.message;
  }
});

export const Reset_Password = createAsyncThunk(
  "auth/Reset_Password",
  async (data) => {
    try {
      const response = await axios.post(`Reset_Password`, data);
      // console.log(response);
      return response.data;
    } catch (error) {
      // console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

// Get_Count_Student;
export const Get_Count_Student = createAsyncThunk(
  "auth/Get_Count_Student",
  async (StudentID) => {
    try {
      const response = await axios.get(`Get_Count_Student/${StudentID}`);
      // console.log(response);
      return response;
    } catch (error) {
      // console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const EditProfile_Student = createAsyncThunk(
  "auth/EditProfile_Student",
  async (formData) => {
    try {
      // console.log(formData);
      // console.log(StudentID);
      const response = await axios.post(
        `Edit_Profile/${formData.StudentID}`,
        formData
      );
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

// Edit_Profile_Password
export const Edit_Profile_Password = createAsyncThunk(
  "auth/Edit_Profile_Password",
  async (formData) => {
    try {
      // console.log(formData);
      const response = await axios.post(
        `Edit_Profile_Password/${formData.StudentID}`,
        formData
      );
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

//
export const SyncAlluser_Data_ScienceData = createAsyncThunk(
  "auth/SyncAlluser_Data_ScienceData",
  async () => {
    try {
      const response = await axios.get(`SyncAlluser_Data_ScienceData`);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const SyncAlluser_Data_FantasyData = createAsyncThunk(
  "auth/SyncAlluser_Data_FantasyData",
  async () => {
    try {
      const response = await axios.get(`SyncAlluser_Data_FantasyData`);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const SyncAlluser_Data_MysteryData = createAsyncThunk(
  "auth/SyncAlluser_Data_MysteryData",
  async () => {
    try {
      const response = await axios.get(`SyncAlluser_Data_MysteryData`);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const SyncAlluser_Data_HistoryData = createAsyncThunk(
  "auth/SyncAlluser_Data_HistoryData",
  async () => {
    try {
      const response = await axios.get(`SyncAlluser_Data_HistoryData`);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const SyncAlluser_Data_AdventureData = createAsyncThunk(
  "auth/SyncAlluser_Data_AdventureData",
  async () => {
    try {
      const response = await axios.get(`SyncAlluser_Data_AdventureData`);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);
export const SyncAlluser_Data_SportData = createAsyncThunk(
  "auth/SyncAlluser_Data_SportData",
  async () => {
    try {
      const response = await axios.get(`SyncAlluser_Data_SportData`);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const Fetch_Partner_With_Us_Data = createAsyncThunk(
  "auth/Fetch_Partner_With_Us_Data",
  async (formData) => {
    try {
      console.log(formData, "Formdata at partner with us ---------------");
      const response = await axios.post(`Fetch_Partner_With_Us_Data`, formData);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

// Activcate_BuyPlan;
export const Activate_BuyPlan = createAsyncThunk(
  "auth/Activcate_BuyPlan",
  async (formData) => {
    try {
      console.log(
        formData,
        "-------------------formdata at the Activate_BuyPlan"
      );
      const response = await axios.post(`Activate_BuyPlan`, formData);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const GetSessionDetails = createAsyncThunk(
  "auth/GetSessionDetails",
  async (sessionID) => {
    try {
      console.log(sessionID);
      const response = await axios.get(`session/${sessionID}`);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const Cancel_Current_Plan = createAsyncThunk(
  "auth/Cancel_Current_Plan",
  async (formData) => {
    try {
      console.log(formData);
      const response = await axios.post(`Cancel_Current_Plan`, formData);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

// SignUp_user_via_token;
export const SignUp_user_via_token = createAsyncThunk(
  "auth/SignUp_user_via_token",
  async (formData) => {
    try {
      console.log(formData);
      const response = await axios.post(`SignUp_user_via_token`, formData);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

//
export const AllReferrals = createAsyncThunk("auth/AllReferrals", async () => {
  try {
    const response = await axios.get(`AllReferrals`);
    // console.log(response);
    return response;
  } catch (error) {
    console.log(error.response.data.message);
    return error.response.data.message;
  }
});

export const Generate_token_for_admin = createAsyncThunk(
  "auth/Generate_token_for_admin",
  async () => {
    try {
      const response = await axios.get("Generate_token_for_admin");
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const Create_AllReferral = createAsyncThunk(
  "auth/Create_AllReferral",
  async (formData) => {
    // console.log(formData);
    try {
      const response = await axios.post("Create_AllReferral", formData);
      console.log(response, "--------------Create_AllReferral");
      return response;
    } catch (error) {
      console.log(error.response.data.message);
    }
  }
);

// SignUp_Tutor_via_token;
export const SignUp_Tutor_via_token = createAsyncThunk(
  "auth/SignUp_Tutor_via_token",
  async (formData) => {
    console.log(formData, "-------------formData at the tutor action");
    try {
      const response = await axios.post("SignUp_Tutor_via_token", formData);
      console.log(response, "--------------SignUp_Tutor_via_token");
      return response.data;
    } catch (error) {
      console.log(error.response.data.message);
    }
  }
);

// GetAllStudent_Tutor_Data;
export const GetAllStudent_Tutor_Data = createAsyncThunk(
  "auth/GetAllStudent_Tutor_Data",
  async (TutorID) => {
    console.log(TutorID, "-------------formData at the tutor action");
    try {
      const response = await axios.get(`GetAllStudent_Tutor_Data/${TutorID}`);
      console.log(response, "--------------GetAllStudent_Tutor_Data");
      return response;
    } catch (error) {
      console.log(error.response.data.message);
    }
  }
);

// Update_Student_Tutor/:StudentID
export const Update_Student_Tutor = createAsyncThunk(
  "auth/Update_Student_Tutor",
  async (FormData) => {
    console.log(FormData, "-------------formData at the tutor action");
    const StudentID = FormData.StudentID;
    try {
      const response = await axios.post(
        `Update_Student_Tutor/${StudentID}`,
        FormData
      );
      console.log(response, "--------------Update_Student_Tutor response");
      return response;
      // FoundedStudent;
    } catch (error) {
      console.log(error.response.data.message);
    }
  }
);

// StudentID;
// Update_Student_Tutor/:StudentID
export const Delete_Student = createAsyncThunk(
  "auth/Delete_Student",
  async (FormData) => {
    try {
      console.log(FormData, "-------------------FormData at the delete action ---------");
      const response = await axios.post(`Delete_Student` , FormData);
      return response;
      // FoundedStudent;
    } catch (error) {
      console.log(error.response.data.message);
    }
  }
);


// Edit_Tutor_Profile;
export const Edit_Tutor_Profile = createAsyncThunk(
  "auth/Edit_Tutor_Profile",
  async (FormData) => {
    try {
      console.log(FormData);
      const response = await axios.post(`Edit_Tutor_Profile`, FormData);
      return response;
      // FoundedStudent;
    } catch (error) {
      console.log(error.response.data.message);
    }
  }
);


// GetAll_TutorDetails
export const GetAll_TutorDetails = createAsyncThunk(
  "auth/GetAll_TutorDetails",
  async () => {
    try {
      // console.log(FormData);
      const response = await axios.get(`GetAll_TutorDetails`);
      return response;
      // FoundedStudent;
    } catch (error) {
      console.log(error.response.data.message);
    }
  }
);


// Change_Tutor_Password
export const Change_Tutor_Password = createAsyncThunk(
  "auth/Change_Tutor_Password",
  async (formData) => {
    try {
      console.log(formData , "-----------------formData at the action of the Change Password");
      const response = await axios.post(`Change_Tutor_Password`, formData);
      return response;
      // FoundedStudent;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message
    }
  }
);
