// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { GetAll_TutorDetails } from "../../store/Actions/Authactions";

// const AdminTutorDetails = () => {
//   const disptach = useDispatch();

//   const AllTutor_details = useSelector((state) => state.auth.AllTutor_details);

//   console.log(AllTutor_details, "-------------------AllTutor_details");

//   const [Tutor_refrence_Amount  ,  setTutor_refrence_Amount] =  useState(0)
//   useEffect(() => {
//     disptach(GetAll_TutorDetails());

//     if (AllTutor_details.length  > 0) {

//       let total_Amount_tutor
//       const filteredData  =  AllTutor_details.map((tutor,index) => {
//          tutor.AllReferral_Students.map((val,index) => {
//           total_Amount_tutor  +=   val.Active_Plan_Amount;
//           // make this api so that i will get all the total of the amount fromn the student wjho is enrolled on the this particular tutot
//          })
//       })

      

//     } 

//   }, []);


//   return (
//     <div className="w-full ">
//       {AllTutor_details.length > 0 ? (
//         <table className="w-full text-sm text-left rtl:text-right text-white">
//           <thead className="text-xs text-white uppercase bg-[#03051B]">
//             <tr>
//               <th scope="col" className="px-6 py-3">
//                 Username
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 Email
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 No. of Students Created by Tutor
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 createdAt
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 Total Tutor Referrence Amount
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {AllTutor_details.map((Student, index) => (
//               <tr key={index} className="bg-[#03051B] border-b border-gray-700">
//                 <th
//                   scope="row"
//                   className="px-6 py-4 font-medium text-white whitespace-nowrap"
//                 >
//                   {Student?.Username}
//                 </th>
//                 <td className="px-6 py-4">{Student?.Email}</td>
//                 <td className="px-6 py-4">
//                   {Student?.AllReferral_Students.length}
//                 </td>
//                 {/* createdAt */}
//                 <td className="px-6 py-4">{Student?.createdAt}</td>
//                 <td className="px-6 py-4">{Student?.Tutor_refrence_Amount}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       ) : null}
//     </div>
//   );
// };

// export default AdminTutorDetails;


import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAll_TutorDetails } from "../../store/Actions/Authactions";

const AdminTutorDetails = () => {
  const dispatch = useDispatch();

  const AllTutor_details = useSelector((state) => state.auth.AllTutor_details);

  console.log(AllTutor_details, "-------------------AllTutor_details");

  const [Tutor_refrence_Amount, setTutor_refrence_Amount] = useState(0);

  useEffect(() => {
    // Dispatch action to get all tutor details
    dispatch(GetAll_TutorDetails());
  }, [dispatch]);

  useEffect(() => {
    // Calculate total reference amount once tutor details are fetched
    if (AllTutor_details.length > 0) {
      let total_Amount_tutor = 0;

      AllTutor_details.forEach((tutor) => {
        // Check if AllReferral_Students exists and is an array
        if (
          tutor.AllReferral_Students &&
          tutor.AllReferral_Students.length > 0
        ) {
          tutor.AllReferral_Students.forEach((val) => {
            // Check if Active_Plan_Amount exists and is a number
            if (
              val.Active_Plan_Amount &&
              typeof val.Active_Plan_Amount === "number"
            ) {
              total_Amount_tutor += val.Active_Plan_Amount;
            }
          });
        }
      });

      // Update the state with the calculated total amount
      setTutor_refrence_Amount(total_Amount_tutor);
    }
  }, [AllTutor_details]); // Add AllTutor_details as a dependency

  return (
    <div className="w-full">
      {AllTutor_details.length > 0 ? (
        <table className="w-full text-sm text-left rtl:text-right text-white">
          <thead className="text-xs text-white uppercase bg-[#03051B]">
            <tr>
              <th scope="col" className="px-6 py-3">
                Username
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                No. of Students Created by Tutor
              </th>
              <th scope="col" className="px-6 py-3">
                createdAt
              </th>
              <th scope="col" className="px-6 py-3">
                Total Tutor Reference Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {AllTutor_details.map((Student, index) => (
              <tr key={index} className="bg-[#03051B] border-b border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-white whitespace-nowrap"
                >
                  {Student?.Username}
                </th>
                <td className="px-6 py-4">{Student?.Email}</td>
                <td className="px-6 py-4">
                  {Student?.AllReferral_Students?.length || 0}
                </td>
                <td className="px-6 py-4">{Student?.createdAt}</td>
                <td className="px-6 py-4">{Tutor_refrence_Amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

export default AdminTutorDetails;
